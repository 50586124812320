import React, { useState } from "react";
import { Row, Col, Modal } from "antd";
import "../../assets/css/ponentes.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import sergio_con from "../../assets/images/temp/1800X200.png";
import { CaretLeftFilled, CaretRightFilled, CloseOutlined } from "@ant-design/icons";

import ponente1 from "../../assets/images/primera_2025/ponentes/Carlos Loret de Mola.jpg";
import ponente2 from "../../assets/images/primera_2025/ponentes/Claudia Dittrich.jpg";
import ponente3 from "../../assets/images/primera_2025/ponentes/Diana Torres.jpg";
import ponente4 from "../../assets/images/primera_2025/ponentes/Jimena Tolama.jpg";

import ponente5 from "../../assets/images/primera_2025/ponentes/Sandra Solis.jpg";
import ponente6 from "../../assets/images/primera_2025/ponentes/enriquePerret.jpg";
import ponente7 from "../../assets/images/primera_2025/ponentes/EmilianoSanchez.png";
import ponente8 from "../../assets/images/primera_2025/ponentes/DavidGoldwyn.jpg";
import ponente9 from "../../assets/images/primera_2025/ponentes/JesusDanielHernandez.png";
import ponente10 from "../../assets/images/primera_2025/ponentes/Juan-Miguel-Rolland.png";

import ponente11 from "../../assets/images/primera_2025/ponentes/OmarRamirez.png";
import ponente12 from "../../assets/images/primera_2025/ponentes/JavierUgarte.png";

const expositor = [
  //{nombre:'', img: avatar,img_hover:avatar_hover, hash:'/conferencistas#' },
  {
    nombre: "Carlos Loret de Mola",
    empresa: "",
    cargo: "",
    img: ponente1,
    url: sergio_con,
    semblanza: [<>Carlos Loret de Mola es uno de los periodistas más influyentes de México. Es fundador de la plataforma Latinus que rápidamente se volvió uno de los medios de comunicación más importantes del país. Ahí conduce todas las noches el programa Loret en Latinus. También tiene un noticiario diario en W Radio y escribe tres veces a la semana la columna Historias de Reportero en el periódico El Universal. Loret nunca ha dejado de ser reportero y se le conoce por sus coberturas de guerra y de algunos de los acontecimientos políticos y desastres naturales más graves en la historia reciente. Ha escrito libros sobre el narcotráfico y las guerras que ha cubierto, a la vez que ha participado en varios documentales cinematográficos de talla internacional. 
    </>],
  },{
    nombre: "Claudia Dittrich",
    empresa: "",
    cargo: "",
    img: ponente2,
    url: sergio_con,
    semblanza: [null],
  },{
    nombre: "Diana Torres",
    empresa: "",
    cargo: "",
    img: ponente3,
    url: sergio_con,
    semblanza: [<>
      Diana Torres es gerente de administración de activos en GP Desarrollos, donde ha demostrado su compromiso y liderazgo. Teniendo como soporte 17 años de experiencia en el sector industrial. Su trayectoria profesional se ha caracterizado por la dedicación y  pasión por su trabajo.
      <br /><br />
      En 2024, Diana lideró con éxito a su equipo en la obtención del distintivo de "parque industrial seguro" para el Pocket Park Oriente y el Pocket Park Aeropuerto.      
    </>],
  },{
    nombre: "Enrique Perret",
    empresa: "",
    cargo: "",
    img: ponente6,
    url: sergio_con,
    semblanza: [<>Enrique es un destacado líder con una larga trayectoria de compromiso con la relación bilateral entre Estados Unidos y México. Durante su tiempo en el gobierno mexicano, Enrique se distinguió por liderar compromisos gubernamentales y estrategias de desarrollo empresarial entre ambos países. Actualmente se desempeña como Director Ejecutivo de la US-Mexico Foundation y es el Presidente y Fundador del North Capital Forum, una iniciativa de la USMF que busca posicionar a América del Norte como una potencia económica, social y política.
<br /><br />
      Antes de asumir estos roles, Enrique fue Director para América del Norte en ProMéxico, la agencia de promoción del gobierno mexicano, donde trabajó durante más de diez años en diversas capacidades. Otros cargos que ocupó en el gobierno mexicano incluyen Secretario Técnico de Competitividad y Jefe de Gabinete del Presidente de la CNBV (Comisión Nacional Bancaria y de Valores).
      <br /><br />
      Además, Enrique ha sido un activo defensor de los esfuerzos de la sociedad civil y ha ocupado roles de liderazgo en varias instituciones, como miembro del consejo directivo de Enseña por México (capítulo mexicano de Teach for All) y del patronato de Reinserta. También es miembro de los consejos de Fundemex, Nepanoa, Pacto y COMEXUS, y Presidente y Fundador del Grupo México en Movimiento.
      <br /><br />
      Enrique tiene una licenciatura en Comercio Internacional del Instituto Tecnológico y de Estudios Superiores de Monterrey, así como una maestría en Economía y Gobierno de la Universidad Anáhuac. También ha cursado programas ejecutivos en la Universidad de Harvard, la Wharton School y SMU.
      <br /><br />
      Además de sus logros profesionales, Enrique es Productor Ejecutivo de la serie de televisión "Pati's Mexican Table", reconocida con cuatro premios James Beard y tres nominaciones al Emmy, y de "La Frontera" en PBS.      
    </>],
  },{
    nombre: "Sandra Solis",
    empresa: "",
    cargo: "",
    img: ponente5,
    url: sergio_con,
    semblanza: [null]
  },{
    nombre: "Jimena Tolama",
    empresa: "",
    cargo: "",
    img: ponente4,
    url: sergio_con,
    semblanza: [<>Directora de Noticias para México-Cono Norte en Bloomberg Línea. Periodista mexicana con 10 años de trayectoria en medios de negocios multiplataforma. Fue cofundadora y editora en Jefe de EL CEO, lo que la convirtió en la mujer más joven al frente de un sitio económico financiero en México. Apasionada por crear nuevas formas de contar historias.​</>],
  },{
    nombre: "Emiliano Sánchez Rosales",
    empresa: "",
    cargo: "",
    img: ponente7,
    url: sergio_con,
    semblanza: [<>Emiliano Sánchez Rosales es un destacado economista egresado del Instituto Tecnológico Autónomo de México (ITAM), con una sólida trayectoria en el sector energético, especialmente en el Mercado Eléctrico Mayorista (MEM) donde ha desempeñado roles clave desde su operación inicial en 2018.
<br /><br />
      Inició su carrera en Blue Energy and Electricity (B2E), donde, a lo largo de cuatro años, escaló posiciones desde intern hasta asociado de trading. Durante su tiempo en Blue Energy, Emiliano fue fundamental en la gestión y optimización del portafolio energético de la compañía, además de liderar negociaciones importantes relacionadas con coberturas energéticas y ajustes de precios para clientes de suministro.
      <br /><br />
      En 2022, Emiliano dio un paso adelante en su carrera al unirse a E2M (Energy to Market) como director de operaciones de Suministro Calificado y Generador. En este rol, estuvo a cargo de optimizar procesos operativos y de gestionar el equilibrio de portafolios de energía y capacidad, así como de realizar análisis estratégicos para la fijación de precios y coberturas.
      <br /><br />
      Consolidando su expertise y liderazgo, en febrero de 2024, Emiliano se incorporó a Energía Real para liderar y desarrollar el área de Suministro Calificado como director. Su enfoque está en fortalecer la posición de Energía Real en el mercado, garantizando soluciones energéticas eficientes y sostenibles que respondan a las necesidades de los clientes y contribuyan a la transición energética del país.
      <br /><br />
      Emiliano es reconocido por su profundo entendimiento del mercado energético y su capacidad para implementar soluciones innovadoras que no solo mejoran la operatividad y rentabilidad, sino que también promueven la sostenibilidad ambiental.
      </>],
  },{
    nombre: "David Goldwyn",
    empresa: "",
    cargo: "",
    img: ponente8,
    url: sergio_con,
    semblanza: [<>David Goldwyn is president of Goldwyn Global Strategies, LLC (GGS), an international energy advisory consultancy, and Chairman of the Atlantic Council Global Energy Center’s Energy Advisory Group. He is a globally recognized thought leader, educator and policy innovator in energy security and extractive industry transparency. Mr. Goldwyn served as the US State Department’s special envoy and coordinator for international energy affairs from 2009 to 2011 and assistant secretary of energy for international affairs (1999-2001), the only person to hold both the US government’s international energy leadership positions. He also served as national security deputy to US Ambassador to the United Nations Bill Richardson (1997-98) and chief of staff to the U.S. Under-Secretary of State for Political Affairs (1993-97). Mr. Goldwyn has been published extensively on topics related to energy security and transparency. He is the co-editor of Energy & Security: Strategies for a World in Transition (Wilson Center Press/Johns Hopkins University Press 2013). 
<br /><br />
    Mr. Goldwyn’s recent publications include “Tariffs on Canada and Mexico could hurt Trump’s quest for US energy dominance,” (Atlantic Council 2025, co-authored with Joseph Webster), “Pragmatism can improve Mexico's energy outlook,” (Atlantic Council 2024, co-authored with Antonio Ortiz Mena), a book chapter in “The Next President of the United States: Challenges and Recommendations for the US-Mexico Relationship” (Wilson Center 2024, co-authored with Lourdes Melgar), “Reducing US industrial emissions under budgetary uncertainty" (Atlantic Council 2024, co-authored with Andrea Clabough), “What will a Trump or Harris administration mean for climate and energy policy?” (Atlantic Council 2024, co-authored with Andrea Clabough), “A Roadmap for the Caribbean’s Energy Transition,” (Atlantic Council, 2023), “Six steps Guyana can take to avoid the resource curse,” (Atlantic Council 2023), “A New Energy Strategy for the Americas” (Atlantic Council, 2020) and “Confronting the Resource Curse: Advice for Investors and Partners” (Baker Institute 2020). Mr. Goldwyn holds a B.A. in Government from Georgetown University, an M.A. in Public Affairs from Princeton University School of Public and International Affairs and a J.D. from New York University. 	
    </>],
  },{
    nombre: "Jesus Daniel Hernández ",
    empresa: "",
    cargo: "",
    img: ponente9,
    url: sergio_con,
    semblanza: [<>Soy Licenciado en Criminología, egresado en 2012 de la Facultad de Derecho y Criminología de la UANL, con especialización en Seguridad y Protección Privada. Desde el inicio de mi carrera, he tenido la oportunidad de asumir responsabilidades clave en el ámbito de la seguridad, lo que ha enriquecido mi experiencia profesional y me ha permitido crecer en el sector.
 <br /><br />
      En 2010, recibí la oportunidad de liderar la Seguridad Patrimonial en Grupo Bimbo, un cargo que desempeñé mientras continuaba con mis estudios hasta mi graduación. En 2014, me uní a FEMSA Comercio, donde asumí el rol de Coordinador de Protección Patrimonial y Laboral en Cadena Comercial OXXO, a cargo de la plaza de Monterrey y la zona metropolitana, hasta 2018.
      <br /><br />
      En 2018, fui promovido a Gerente Regional de Seguridad Operativa en Grupo Salinas, supervisando las operaciones en los estados del norte del país hasta 2023.
      <br /><br />
      Actualmente, lidero la Seguridad Patrimonial a nivel nacional en FINSA Parques Industriales, donde he contribuido a que dos parques obtuvieran la certificación de Operador Económico Autorizado, y seis parques recibieran el distintivo de Parque Industrial Seguro otorgado por la AMPIP. Mi compromiso con la seguridad sigue siendo firme, y estoy trabajando activamente para que más parques industriales de FINSA logren estos prestigiosos distintivos.
      <br /><br />
      A lo largo de mi trayectoria, las oportunidades brindadas por empresas de prestigio me han permitido desarrollar competencias clave en áreas como seguridad física y patrimonial, investigación de hechos, el marco legal de la seguridad privada y certificaciones de seguridad. Asimismo, he logrado establecer relaciones sólidas con autoridades y cuerpos de seguridad pública. Mi enfoque en protección ejecutiva también ha fortalecido mi capacidad para ofrecer soluciones integrales en el ámbito de la seguridad.
      
    </>],
  },{
    nombre: "Juan Miguel Rolland",
    empresa: "",
    cargo: "",
    img: ponente10,
    url: sergio_con,
    semblanza: [<>Licenciado en Ingeniería en Computación, Juan Miguel Rolland es un experto en la intersección entre tecnología y marketing digital. Como director y socio de De3, ha liderado durante más de 10 años estrategias digitales para cientos de sitios web y campañas, colaborando con clientes de alto nivel como RECUBRE, IMEMSA, GMI, IBERO y ANÁHUAC, entre otros.<br /><br />
      Su formación académica incluye una Maestría en Marketing Digital por el E-eCommerce Institute y un MBA por la Universidad Anáhuac, lo que le permite combinar una visión estratégica con un profundo conocimiento del ecosistema digital y de negocios.<br /><br />
      Además de su labor en el ámbito empresarial, Juan Miguel Rolland es un apasionado de la educación. Desde hace 6 años, imparte clases en el E-commerce Institute, formando a las nuevas generaciones de profesionales en comercio electrónico y estrategias digitales. Asimismo, desde hace un año es docente en la Universidad Anáhuac del Sur, donde comparte su experiencia y conocimientos con estudiantes de alto nivel.<br /><br />
      Con una trayectoria consolidada en la industria digital, Juan Miguel Rolland se ha convertido en un referente en la implementación de estrategias innovadoras que potencian el crecimiento de empresas a través del marketing digital y la tecnología.
    </>],
  },{
    nombre: "Javier Ugarte de la Rosa",
    empresa: "",
    cargo: "",
    img: ponente12,
    url: sergio_con,
    semblanza: [<>Javier Ugarte ha sido un actor clave en el desarrollo industrial de México, liderando la planeación estratégica, administración de proyectos y ejecución de ingeniería y construcción de parques y edificios industriales y comerciales. Desde su incorporación en 1993, ha contribuido al crecimiento de FINSA, empresa con 48 años en el mercado y más de 14 millones de m² construidos, consolidándola como el principal desarrollador industrial del país.
    <br /><br />
      Pionero en la implementación de prácticas sostenibles, fue responsable del primer edificio industrial certificado LEED en México en 2009 y promovió la certificación de más de 30 edificios en el país, además de gestionar la certificación ambiental de los parques industriales Finsa ante la PROFEPA. Como representante de México en el Consejo Consultivo de BUTLER Manufacturing en Kansas City (2015-2016), promovió la innovación en materiales metálicos certificados LEED.
      <br /><br />
      Desde 2020, lidera la iniciativa FINSA Energía, asegurando la infraestructura eléctrica para el crecimiento de la empresa mediante la construcción de subestaciones de alta tensión y el programa de instalación de energía renovable con paneles solares en 12 parques industriales para alumbrado público y servicios generales.
      <br /><br />
      Ingeniero Civil por el Instituto Tecnológico y de Estudios Superiores de Monterrey, donde recibió Mención Honorífica, cuenta con estudios en Administración de la Construcción por la Universidad de Harvard y en Análisis de Estructuras por Métodos Computacionales por la Universidad de Wisconsin - Madison.</>],
  },{
    nombre: "Omar Ramírez",
    empresa: "",
    cargo: "",
    img: ponente11,
    url: sergio_con,
    semblanza: [<>Senior Executive with 20+ years of experience in the Logistics and E-commerce Industries. Excelled in Operations Management, improving customer satisfaction, employee engagement with a solid analytical and financial background.
<br /><br />
      Proven outstanding record in achieving business results by establishing strategies, leading and influencing diverse teams across the organization during challenging periods.
      </>],
  },
];
const Ponentes = (props) => {
  const ref_carousel = React.createRef();
  const [hover, setHover] = useState(false);
  const [selec_person, setSelectPerson] = useState(0);
  const { idioma } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      
      <CaretRightFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px' }} onClick={onClick} />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <CaretLeftFilled className={className} style={{ ...style, display: "block", color:'#022435', fontSize:'34px', marginLeft:'-10px' }} onClick={onClick} />
    );
  }

  const settingsPonentes = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows:true,
    nextArrow:<SampleNextArrow /> ,
    prevArrow:<SamplePrevArrow  />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows:true,
          nextArrow:<SampleNextArrow /> ,
          prevArrow:<SamplePrevArrow  />,
        }
      }
    ]
  };
//sort acomoda alfabeticamente
  let ver_ponentes = expositor.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((seccion, index) => {
    return (
      <>
        <div className="contenedor_imagen_con">
          <img src={seccion.img} className="imagen_con" alt="" />
          <div className="overlay_con">
            <div className="text_con">
              <p className="nombre_con color_blanco bold_font">{seccion.nombre}</p>
              <br />
              <p className="empresa_con color_blanco">{seccion.empresa}</p>
              <p className="cargo_con color_blanco">{seccion.cargo}</p>
              <br />
              <p style={{display:'non'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con color_blanco"
              >
                Leer semblanza
              </span>
              :
              <></>}
              </p>
            </div>
          </div>
        </div>
        <br />
        </>
    );
  });

  let ver_ponentes_2 = expositor.sort((a, b) => a.nombre > b.nombre ? 1 : -1).map((seccion, index) => {
    return (
      <>
        <div className="contenedor_ponente" >
          <p style={{textAlign:'-webkit-center'}}><img src={seccion.img} className="img_circulo" alt="" /></p>
          <br /><br />
          <p className="nombre_con color_blanco bold_font">{seccion.nombre}</p>
             
              {seccion.empresa != '' ?
              <p className="cargo_con color_blanco">{seccion.empresa}</p>
            :
            <><br /></>}
              <br />
              <p style={{display:'non'}}>
                {seccion.semblanza != '' ?
                <span
                onClick={() => {
                  showModal();
                  setSelectPerson(index);
                }}
                className="btn_desc_con color_blanco"
              >
                Leer semblanza
              </span>
              :
              <></>}
              <br /><br /><br />
              </p>
          </div>
        </>
    );
  });

  let ver_texto_modal = expositor
    .filter((item, index) => {
      if (selec_person === index) {
        return true;
      } else {
        return false;
      }
    })
    .map((seccion, index) => {
      return (
        <Col xs={24}>
          <p className="nombre_modal">{seccion.nombre}</p>
          <p className="empresa_modal">{seccion.empresa}</p>
          <p className="cargo_modal">{seccion.cargo}</p>
          <br />
          <p className="semblanza_modal">{seccion.semblanza}</p>
          <br />
        </Col>
      );
    });

  return (
    <>
      <Row justify="center" align="middle" className="contenedor_ponentes">
        <Col span={24} offset={0}>
          <p className="textos_titulos_secciones color_azul">CONFERENCISTAS</p><br />
        </Col>
        <Col xs={14} md={16}>
       <Slider ref={ref_carousel} {...settingsPonentes} style={{margin:'0px', padding:'0px'}} className="slide_ponente">
        {ver_ponentes_2}
        </Slider>
       
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        title={false}
        closeIcon={<CloseOutlined style={{ color: "#ffffff" }} />}
      >
        {ver_texto_modal}
      </Modal>
    </>
  );
};
export default Ponentes;
